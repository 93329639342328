/* CMP IMG-PRELOADER (Ver. 0.1) */

var CMP = CMP || {};

(function ($) {
    "use strict";

    /* NOTA: questo viene eseguito prima che il DOM sia stato caricato del tutto */

    CMP.imgPreloader = function (imgsArray, doneCallback, options) {
        
        var defaults = {
            suffix:'@2x.',
            enableRetina: false
        };
        options = options || {};
        options = $.extend(defaults, options);
      

        if (imgsArray.length > 0 && $.imgpreload) {
            doneCallback = (typeof doneCallback === 'function') ? doneCallback : $.noop;

            // Gestione dei path x retina display. In pratica fa il preload direttamente
            // delle immagini retina se il dispositivo è retina
            if (options.enableRetina == true && isRetina()) {

                var _only2X = [];
                for (var i = 0; i < imgsArray.length; i++) {

                    // Verifico anche se la 2X esiste prima di aggiungerla all'array di preload
                    // e sostituirla nell'html
                    var _2xPath = imgsArray[i].replace(/\.(?=[^.]*$)/, options.suffix)
                    $.ajax({
                        type: 'HEAD',
                        url: _2xPath,
                        async: false
                    }).done(function (data, textStatus, jqXHR) {
                        if (jqXHR.status >= 200 && jqXHR.status <= 399) {
                            var type = jqXHR.getResponseHeader('Content-Type');
                            if (type && type.match(/^image/i)) {
                                imgsArray[i] = _2xPath;
                                _only2X.push(_2xPath);
                            }                            
                        }
                    });                    
                }

                // Ora, sostituisco i 2x nell'html...
                for (var i = 0; i < _only2X.length; i++) {
                    log('Replace image with 2X: ' + _only2X[i]);
                    $('img[src="' + _only2X[i].replace(options.suffix,'.') + '"]').attr('src', _only2X[i]);
                }
            }

            $.imgpreload(imgsArray, function () {
                // this = array of dom image objects
                // check for success with: $(this[i]).data('loaded')
                // callback executes when all images are loaded

                doneCallback(this);
            });
        }

    };

})(jQuery);