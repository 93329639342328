/* CMP MEGAMENU (Ver 0.1) */

var CMP = CMP || {};

(function ($) {
    "use strict";

    CMP.megamenu = function (strSelector, options) {
        
        options = options || {};
        var defaults = {
            openOn: 'click'
        };
        options = $.extend(defaults, options);


        if(options.openOn === 'hover' && !isMobile.any()) {
            $(strSelector).hover(function () {
                $('.main-drop', this).stop( true, true ).fadeIn();
                $(this).toggleClass('open');              
            }, function () {
                $('.main-drop', this).stop( true, true ).fadeOut();
                $(this).toggleClass('open');                
            });
            $(strSelector).on('click', '.dropdown-toggle', function (e) {
                e.stopPropagation(); 
            });
        }

        // This code will prevent unexpected menu close when using some components (like accordion, forms, etc)
        $(document).on('click', '.yamm .dropdown-menu', function (e) {
            e.stopPropagation();
        });

        return this;
    }

})(jQuery);

