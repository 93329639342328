/* CMP PLACES AUTOCOMPLETE (Ver. 0.1) */

var CMP = CMP || {};

(function ($) {
    "use strict";

    CMP.places = {
        _places: [],
        _options:null,
        _$this:null,
        init: function (strSelector, options) {

            var self = this;
            self._options = self._$this = null;
            self._places = [];

            strSelector = strSelector || '.cmp-places-autocomplete';
            options = options || {};
            var defaults = {
                mode: 'tags', // 'tags' (mostra i tags sotto all'input), 'map' (posta il centro della mappa senza aggiungere marker), 'both' (entrambi)
                map: null, // gmap object
                tags_area: '.cmp-places-tags',
                placeChangedCallback: $.noop // parametri di ritorno: lat, lon, adrress
            };
            self._options = $.extend(true, defaults, options);



            self._$this = $(strSelector);
            if (!self._$this)
                return;

            if (self._$this.length > 1) {
                self._$this = self._$this.first();
                log('Places Autocomplete: Questo componente funziona solo a singolo elemento');
            }

            var input = self._$this.find('.cmp-input').get(0);
            if (!input)
                return false;

            if (typeof (google) === 'undefined')
                return false;            

            var $tagsArea = $(self._options.tags_area);
            var $tagsInput = self._$this.find('.cmp-places-tags-input');
        
            // Funzione di delete di un tag
            var _deleteTag = function () {
                $tagsArea.find('i.remove-place').unbind('click');
                $tagsArea.find('i.remove-place').on('click', function () {
                    var index = parseInt($(this).closest('li').attr('data-index'));
                    // Elimino dall'array
                    self._places.splice(index, 1);
                    if (self._places.length > 0)
                        $tagsInput.val(JSON.stringify(self._places));
                    else
                        $tagsInput.val('');
                    // Elimino dalla UI
                    _printAll();
                    // Avviso che è stato eliminato qualcosa
                    $tagsInput.trigger('placeAutocompleteRemove');
                });
            }

            // Funzione che mi aggiunge un place ai tags
            var _addTag = function (_index) {
                if ($tagsArea && $tagsInput) {
                    _index = $.isNumeric(_index) ? _index : (self._places.length - 1);
                    var _last = self._places[_index];

                    $tagsArea.append('<li data-index="' + _index + '">' + _last.address + ' <i class="glyphicon glyphicon-remove remove-place"></i></li>')
                    $tagsInput.val(JSON.stringify(self._places));
                    // Avviso che è stato aggiunto qualcosa
                    $tagsInput.trigger('placeAutocompleteAdd');

                    // Re-init the delete function
                    _deleteTag();
                }
                else {
                    log('CMP Places Autocomplete: no tag-area or no tag-hidden-input');
                }
            };

            // Funzione che mi setta il centro della mappa
            var _centerMap = function () {
                if (self._options.map && typeof (self._options.map) === 'object') {
                    var _index = self._places.length - 1;
                    var _last = self._places[_index];

                    // Ora centro la mappa                
                    self._options.map.setCenter(new google.maps.LatLng(_last.latitude, _last.longitude));
                }
            };
       
            // FUnzione che mi stampa tutti i places da zero
            var _printAll = function () {
                $tagsArea.empty();
                for (var i = 0; i < self._places.length; i++) {
                    _addTag(i);
                }
            };

            // Bind all'evento 'placesFirstLoad' 
            self._$this.bind('placesFirstLoad', function () {                
                _printAll();
            });

            var autocomplete = new google.maps.places.Autocomplete(input, { types: ['geocode'] });
            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();

                var address = place.formatted_address;
                var lat = place.geometry.location.lat();
                var lon = place.geometry.location.lng();
                self._places.push({ latitude: lat, longitude: lon, address: address });

                // MODE
                switch (options.mode) {

                    case 'tags':
                    default:
                        _addTag();
                        break;

                    case 'map':
                        _centerMap();
                        break;

                    case 'both':
                        _addTag();
                        _centerMap();
                        break;
                }

                self._options.placeChangedCallback(lat, lon, address);

                // Ripulisco il campo
                setTimeout(function () {
                    $(input).val('');
                }, 800);
            });


            $tagsInput.data('placesAutocomplete', self);
            return $.extend(true, {}, self);
        },
        load: function (data) {

            var self = this;

            // Primo Load tramite parametro 'data'
            if(data)
            {
                if (typeof (data) === 'object')
                {
                    self._places = data;
                    self._$this.trigger('placesFirstLoad');
                }
                if (typeof (data) === 'string')
                {                                        
                    if (isJson(data)) {
                        self._places = JSON.parse(data);
                    }                    
                }

                self._$this.trigger('placesFirstLoad');
            }
                                                
        }
    };


})(jQuery);