/* CMP AUTOCOMPLETE (Ver 0.2) */

var CMP = CMP || {};

(function ($) {
    "use strict";

    CMP.autocomplete ={
        _data:null,
        _options:null,
        init: function (strSelector, Bloodhound, options) {
            var self = this;
            self._data = self._options = null;

            if ($.fn.typeahead) {

                var $el = $(strSelector);
                if (!$el)
                    return;

                self._data = Bloodhound;

                var $input = $el.find('.typeahead');
                if (!$input)
                    return;

                options = options || {};

                var defaults = {
                    hint: true, // Mostra un suggerimento all'interno dell'input
                    highlight: true, // Evidenzia la parte che matcha con la nostra ricerca
                    minLength: 1, // Numero minimo di caratteri inseriti prima di mostrare l'autocomplete
                    limit: 5, // Numero massimo di suggestion da visualizzare contemporaneamente
                    clearValueIfnotMatchSuggestion: false // Elimina il testo inserito (al focus lost) se non matcha nessuna suggestion
                }

                options = self._options = $.extend(defaults, options);

                
                // Dataset options
                var datasetOpt = {
                    name: 'suggestion',
                    source: Bloodhound, 
                    limit: options.limit
                };

                $input.typeahead(options, datasetOpt);

                // Triggero un evento custom sull'input quando viene scelto un valore
                // tramite TypeHead
                $input.bind('typeahead:change', function (ev, suggestion) {

                    // Se richiesto, ripulisco l'input al blur se il valore non corrisponde ad una suggestion 
                    if (options.clearValueIfnotMatchSuggestion) {
                        var found = false;
                        for (var i = 0; i < self._data.length; i++) {
                            if (self._data[i].toLowerCase() == suggestion.toLowerCase()) {
                                found = true;
                            }
                        }
                        if (!found) {
                            $(this).val('');
                        }
                    }

                    $input.trigger('OnTypeheadValueChange');
                });                

            }

            return $.extend(true, {}, self);
            //return self;
        }
    };

})(jQuery);