/* CMP POPOVER (Ver 0.1) */

(function ($) {
    "use strict";

    // Init popover con testo semplice
    $('[data-toggle="popover"]').not('[data-content-html]').popover()

    // Init popover con testo html
    
    if(isMobile.iOS()) {
        $('[data-toggle="popover"]').removeAttr( "data-trigger"); 
    }
    
    $('[data-toggle="popover"][data-content-html]').popover({
        html: true,
        content: function () {
            var _selector = $(this).attr('data-content-html');
            var _tmpl = $(_selector);
            if (_tmpl)
            {
                return _tmpl.html();
            }
            return "";
        }
    });

})(jQuery);

